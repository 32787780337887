import { useMediaQuery } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import "../styles/Advantages.scss";
import AdvantageCard from "./AdvantageCard";

const Advantages = () => {
  // 翻译hook
  const { t } = useTranslation();

  // 大卡片文字信息
  const bigCardList = [
    {
      number: "01",
      iconName: "cloud",
      title: t("adv1-title"),
      desc: t("adv1-desc"),
    },

    {
      number: "02",
      iconName: "envelope",
      title: t("adv2-title"),
      desc: t("adv2-desc"),
    },
  ];

  // 小卡片文字信息
  const smallCardList = [
    {
      number: "03",
      iconName: "shield",
      title: t("adv3-title"),
      desc: t("adv3-desc"),
    },

    {
      number: "04",
      iconName: "chart",
      title: t("adv4-title"),
      desc: t("adv4-desc"),
    },

    // {
    //   number: "05",
    //   iconName: "cloud",
    //   title: t("adv5-title"),
    //   desc: t("adv5-desc"),
    // },
  ];

  // 媒体查询hook
  // const isPhone = useMediaQuery("(max-width:425px)");

  return (
    <div className="advantages-container">
      <div className="title">
        {t("adv-title")}
        <div className="background-line"></div>
      </div>

      {/* 上方大卡片 */}
      <div className="top-section">
        {bigCardList.map((el) => (
          <AdvantageCard
            big={true}
            number={el.number}
            iconName={el.iconName}
            title={el.title}
            desc={el.desc}
            key={el.number}
          />
        ))}
      </div>

      {/* 下方小卡片 */}
      <div className="bottom-section">
        {smallCardList.map((el) => (
          <AdvantageCard
            big={true}
            number={el.number}
            iconName={el.iconName}
            title={el.title}
            desc={el.desc}
            key={el.number}
          />
        ))}
      </div>
    </div>
  );
};

export default Advantages;
