import "../styles/Intro.scss";

import React from "react";
import i18n from "../i18n/config";
import { useTranslation } from "react-i18next";

const Intro = () => {
  // 翻译hook
  const { t } = useTranslation();

  return (
    <div className="intro-container">
      {/* 背景以及文字区 */}
      <div className="background-layer">
        <div className="intro-title">
          {/* 内联样式 手机屏 英文下字体需要变小 */}
          <span
            style={
              i18n.language === "en"
                ? {
                    fontSize: "30px",
                  }
                : {}
            }
          >
            {t("intro-title")}
          </span>
        </div>

        <div className="intro-text">
          <span
            style={
              i18n.language === "en"
                ? {
                    fontSize: "14px",
                    lineHeight: "22px",
                    height: "194px",
                    display:"block"
                  }
                : {}
            }
          >
            {t("intro-desc")}
          </span>
        </div>

        {/* 四张图片区 */}
      </div>
      
          <img
            className="image-1"
            style={{ width: "320px", height: "259px" }}
            src="images/1.png"
            alt="intro1"
          />
          <img
            style={{ width: "426px", height: "260px" }}
            src="images/2.png"
            className="image-2"
            alt="intro2"
          />
          <img
            style={{ width: "280px", height: "490px" }}
            className="image-3"
            alt="intro3"
            src="images/3.png"
          />
          <img
            className="image-4"
            style={{ width: "756px", height: "413px" }}
            src="images/4.png"
            alt="intro4"
          />
        </div>
  );
};

export default Intro;
