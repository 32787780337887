import { useMediaQuery } from "@mui/material";
import React from "react";
import i18n from "../i18n/config";
import "../styles/ApplicationCard.scss";

const ApplicationCard = ({ number, imageName, name, desc }) => {
  const isPhone = useMediaQuery("(max-width:425px)");

  return (
    <div className="card-container">
      <div className="header-top">
        <h1 className="number">{number}</h1>
      </div>
      <div className="header-bottom"></div>

      <div className="content">
        <img
          src={`images/${imageName}.png`}
          className="image"
          alt="game"
          style={
            i18n.language === "en"
              ? isPhone
                ? { width: "60px", height: "60px" }
                : {}
              : {}
          }
        />
        <span className="name">{name}</span>
        <span
          className="detail"
          style={
            i18n.language === "en" ? (isPhone ? { fontSize: "10px" } : {}) : {}
          }
        >
          {desc}
        </span>
      </div>
      <div className="footer-left"></div>
      <div className="footer-right"></div>
    </div>
  );
};

export default ApplicationCard;
