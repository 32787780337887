import "../styles/Start.scss";

import Button from "react-bootstrap/Button";
import React from "react";
import i18n from "../i18n/config";
import { useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";

const Start = () => {
  // 切换语言hook
  const { t } = useTranslation();
  const currentLanguage = i18n.language;
  // 媒体查询hook
  const isPhone = useMediaQuery("(max-width:425px)");

  // 点击开始事件 滑动到联系我们
  // const handleStart = () => {
  //   let anchorElement = document.getElementsByClassName("contact-container")[0];
  //   if (anchorElement) {
  //     anchorElement.scrollIntoView({ behavior: "smooth" });
  //   }
  // };

  return (
    <div className="start-container">
      {/* 左侧背景与文字 */}
      <div className="left-section-container">
        <div className="left-section-content">
          {/* 内联样式 手机屏 英文下字体需要变小 */}
          <span
            style={
              currentLanguage === "en"
                ? isPhone
                  ? {
                      fontSize: "25px",
                    }
                  : {
                      fontSize: "50px",
                    }
                : {}
            }
          >
            {t("start1")}
            {!isPhone || currentLanguage === "en" ? <br /> : null}
            {t("start2")}
          </span>
          <span
            style={
              currentLanguage === "en"
                ? isPhone
                  ? {
                      fontSize: "15px",
                    }
                  : {
                      fontSize: "28px",
                    }
                : {}
            }
          >
            {t("start3")}
          </span>
          <a href="https://demo.utcpay.com" target="_blank" rel="noreferrer">
          <Button
            variant="outline-primary"
            id="start-button"
            // onClick={handleStart}
          >
            {t("start-btn")}
          </Button></a>

          <img src="images/book1.png" alt="book1" id="book1"></img>
        </div>
      </div>
      {/* 右侧图片与背景 */}
      <div className="right-section-container">
        <img src="images/book2.png" alt="book2" id="book2"></img>
        <img src="images/start-pc.png" alt="start-pc" id="start-pc"></img>
      </div>
    </div>
  );
};

export default Start;
