import "./styles/App.scss";
import "./components/Header.js";
import Header from "./components/Header.js";
import Start from "./components/Start";
import Intro from "./components/Intro";
import Application from "./components/Application";
import Function from "./components/Function";
import Advantages from "./components/Advantages";
import ContactUs from "./components/ContactUs";
import { useEffect } from "react";
// import i18n from "./i18n/config";
import useMediaQuery from "@mui/material/useMediaQuery";
import IntroMobile from "./components/IntroMobile";
import { useTranslation } from "react-i18next";

function App() {
  const { t } = useTranslation();
  // 媒体查询hook
  const isPhone = useMediaQuery("(max-width:425px)");
  // const isBigScreen = useMediaQuery("(min-width:1400px)");
  const handleScreenAuto = () => {
    const designDraftWidth = 1440; //设计稿的宽度
    const designDraftHeight = 900; //设计稿的高度
    //根据屏幕的变化适配的比例
    const scale =
      document.documentElement.clientWidth /
        document.documentElement.clientHeight <
      designDraftWidth / designDraftHeight
        ? document.documentElement.clientWidth / designDraftWidth
        : document.documentElement.clientHeight / designDraftHeight;
    // const scale = document.documentElement.clientWidth / designDraftWidth;

    //缩放比例
    document.querySelector(
      "#screen"
    ).style.transform = `scale(${scale}) translate(-50%)`;

    // 超长屏时 设置超长背景
    const setBgWithAndTop = (
      bgClassName,
      elementClassName,
      times,
      isFooter
    ) => {
      // 找到bg和对应和transform过的section
      // 然后找到高度，设置背景高度与offsetTop
      const bg = document.querySelector(bgClassName);
      const container = document.querySelector(elementClassName);
      const sectionHeight = container.getBoundingClientRect().height;
      bg.style.height = sectionHeight + "px";

      // footer的计算不一样所以单独写
      if (isFooter) {
        const startContainer = document.querySelector(".start-container");
        const startHeight = startContainer.getBoundingClientRect().height;
        const newOffset = startHeight * 5;
        bg.style.top = newOffset + "px";
      } else {
        bg.style.top = times * sectionHeight + "px";
      }
    };

    setBgWithAndTop(".bg-start", ".start-container", 0);
    setBgWithAndTop(".bg-function", ".function-container", 3);
    setBgWithAndTop(".bg-advantages", ".advantages-container", 4);
    // setBgWithAndTop(".bg-contact", ".contact-container", 5);
    setBgWithAndTop(".bg-footer", "footer", 37, true);
  };

  // 手机模式下退出自适应
  const resetScale = () => {
    document.querySelector("#screen").style.transform = `scale(1)`;
  };

  useEffect(() => {
    //初始化自适应  ----在刚显示的时候就开始适配一次
    if (!isPhone) {
      handleScreenAuto();
    } else {
      resetScale();
    }
    //绑定自适应函数   ---防止浏览器栏变化后不再适配
    window.onresize = () => {
      if (!isPhone) {
        handleScreenAuto();
      } else {
        resetScale();
      }
    };

    //退出大屏后自适应消失
    return () => (window.onresize = null);
  }, [isPhone]);

  return (
    <div className="App">
      {/* 超长屏时需要的背景 */}
      <div className="bg-start"></div>
      <div className="bg-function"></div>
      <div className="bg-advantages"></div>
      {/* <div className="bg-contact"></div> */}
      <div className="bg-footer"></div>

      {/* 主页组件 */}
      <div className="screen" id="screen">
        <Header />
        <Start />
        {isPhone ? <IntroMobile /> : <Intro />}
        <Application />
        <Function />
        <Advantages />
        <ContactUs />
      </div>
      {!isPhone && <a className="link" target="_blank" href="https://demo.utcpay.com" rel="noreferrer">{t('demo')}</a>}
    </div>
  );
}

export default App;
