import React from "react";
import { useTranslation } from "react-i18next";
import i18n from "../i18n/config";
import "../styles/IntroMobile.scss";

const IntroMobile = () => {
  const { t } = useTranslation();

  return (
    <div className="intro-mobile-container">
      <div className="intro-mobile-text">
        <span
          className="intro-mobile-title"
          style={
            i18n.language === "en"
              ? {
                  fontSize: "24px",
                }
              : {}
          }
        >
          {t("intro-title")}
        </span>
        <span className="intro-mobile-desc">{t("intro-desc")}</span>
      </div>

      <div className="img-section">
        <img
          className="image-mobile-1"
          style={{ width: "226px", height: "186px" }}
          src="images/1.png"
          alt="intro1"
        />
        <img
          style={{ width: "216px", height: "133px" }}
          src="images/2.png"
          className="image-mobile-2"
          alt="intro2"
        />
        <img
          style={{ width: "143px", height: "266px" }}
          className="image-mobile-3"
          alt="intro3"
          src="images/3.png"
        />
        <img
          className="image-mobile-4"
          style={{ width: "320px", height: "200px" }}
          src="images/4.png"
          alt="intro4"
        />
      </div>
    </div>
  );
};

export default IntroMobile;
