import React from "react";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer>
      <span>©2023 by UtcPay foundation All Rights Reserved</span>
      <div className="iconList">
        <span>{t('contact-title')}: <a href="mailto:bd@utcpay.com">bd@utcpay.com</a></span>
        <a href="https://t.me/UtcPay" target="_blank" rel="noreferrer"><img src="images/Telegram.png" alt="Telegram" /></a>
        <a href="https://discord.gg/Hy9Vu3zR" target="_blank" rel="noreferrer"><img src="images/Discord.png" alt="Discord" /></a>
        <a href="https://twitter.com/UtcPay_Web3" target="_blank" rel="noreferrer"><img src="images/Twitter.png" alt="Twitter" /></a>
        <a href="https://medium.com/@utcpayprotocol" target="_blank" rel="noreferrer"><img src="images/Medium.png" alt="Medium" /></a>
      </div>
    </footer>
  );
};

export default Footer;
