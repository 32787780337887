import "../styles/ContactUs.scss";
// import Button from "react-bootstrap/Button";
// import Form from "react-bootstrap/Form";
import Footer from "./Footer";
// import { useTranslation } from "react-i18next";
// import Swal from "sweetalert2";

import React from "react";

const ContactUs = () => {
  // const { t } = useTranslation();

  // const formRef = useRef(null);

  return (
    <div className="contact-container">
      {/* <div className="title">
        {t("contact-title")}
        <div className="background-line"></div>
      </div>

      <div className="content">
        <div className="content-header">
          <p>{t("contact-sub1")}</p>
          <p>{t("contact-sub2")}</p>
        </div> */}

        {/* 表单 */}
        {/* <div className="content-form">
          <Form ref={formRef}>
            <Form.Group className="mb-3">
              <Form.Label>{t("form1")}</Form.Label>
              <Form.Control type="text" placeholder="ToMu" required />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>{t("form2")}</Form.Label>
              <Form.Control
                type="email"
                placeholder="example@gmail.com"
                required
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>{t("form3")}</Form.Label>
              <Form.Control type="number" placeholder="55555-5555" required />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>{t("form4")}</Form.Label>
              <Form.Control
                type="text"
                placeholder="https://example.com/"
                required
              />
            </Form.Group>
            <Button
              id="form-button"
              type="submit"
              onClick={(event) => {
                // 需要改写以下逻辑
                if (formRef.current.checkValidity() === false) {
                } else {
                  event.preventDefault();
                  event.stopPropagation();

                  const arr = document.querySelectorAll("input");
                  for (const item of arr) {
                    item.value = "";
                  }
                  Swal.fire({
                    title: t("submit-alert-title"),
                    text: t("submit-alert-msg"),
                    icon: "success",
                  });
                }
              }}
            >
              {t("form-submit")}
            </Button>
          </Form>
        </div>
      </div> */}
      <Footer />
    </div>
  );
};

export default ContactUs;
