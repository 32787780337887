import { useMediaQuery } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import i18n from "../i18n/config";

const AdvantageCard = ({ big, number, title, desc, iconName }) => {
  const isPhone = useMediaQuery("(max-width:425px)");

  // eslint-disable-next-line no-unused-vars
  const { _ } = useTranslation();
  return (
    <div
      className={big ? "card-container-big" : "card-container-small"}
      style={
        i18n.language === "en"
          ? {
              paddingTop: "10px",
            }
          : {}
      }
    >
      <p className="card-number">{number}</p>
      <p className="card-title">{title}</p>
      <p
        className="card-desc text-start"
        style={
          i18n.language === "en"
            ? isPhone
              ? {
                  lineHeight: "18px",
                  marginTop: "5px",
                  fontSize: "14px",
                }
              : {
                  lineHeight: "20px",
                }
            : {}
        }
      >
        {desc}
      </p>

      <img
        src={`images/${iconName}.png`}
        alt={iconName}
        className="card-icon"
      />
    </div>
  );
};

export default AdvantageCard;
