import { useMediaQuery } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import i18n from "../i18n/config";
import "../styles/Function.scss";

const Function = () => {
  // 翻译的hook
  const { t } = useTranslation();
  // 查看是否是手机
  const isPhone = useMediaQuery("(max-width:425px)");

  return (
    <div className="function-container">
      {/* 左侧文字与背景 */}
      <div className="left-section">
        <span className="function-background">UtcPay</span>
        <div
          className="function-title"
          style={
            i18n.language === "en"
              ? isPhone
                ? {
                    fontSize: "25px",
                  }
                : {
                    fontSize: "44px",
                  }
              : {}
          }
        >
          {t("function-title1")} <br />
          {t("function-title2")}
        </div>
        <div className="function-explain">{t("function-desc")}</div>
      </div>
      <img
        src="images/screen-shot.png"
        className="function-image"
        alt="screen-shot"
      />

      {/* 背景圆 */}
      <div className="circle"></div>
    </div>
  );
};

export default Function;
