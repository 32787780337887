import React from "react";
import { useTranslation } from "react-i18next";
import "../styles/Application.scss";
import ApplicationCard from "./ApplicationCard";

const Application = () => {
  const { t } = useTranslation();
  const cardList = t('cards', { returnObjects: true });

  return (
    <div className="application-container">
      {/* 标题 */}
      <div className="title">
        {t("application-title")}
        <div className="background-line"></div>
      </div>

      {/* 卡片list */}
      <div className="card-list">
        {cardList.map((el) => (
          <ApplicationCard
            number={el.number}
            imageName={el.imageName}
            name={el.name}
            desc={el.desc}
            key={el.number}
          />
        ))}
      </div>

      {/* 背景图 */}
      <img src="images/app-bg.png" alt="app-bg" id="app-bg" />
      <img src="images/dots.png" alt="dots" id="dots" />
    </div>
  );
};

export default Application;
