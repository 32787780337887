import { useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import "../styles/Header.scss";

const Header = () => {
  const { i18n } = useTranslation();
  //当前语言state
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);

  //   所有语言的array
  const allLanguageList = ["en", "zh"];

  // 点击切换语言事件
  const changeLanguage = (language) => {
    setCurrentLanguage(language);

    i18n.changeLanguage(language);

    // 切换字体
    const appElement = document.getElementsByTagName("body")[0];
    if (language === "en") {
      appElement.setAttribute("style", "font-family:HelveticaNeue !important");
    } else {
      appElement.setAttribute("style", "font-family:SourceHanSans !important");
    }
  };

  // 下拉框选项
  const DropDownItemList = allLanguageList.map(
    (language, index) =>
      language !== currentLanguage && (
        <Dropdown.Item key={index} onClick={() => changeLanguage(language)}>
          {language === "zh" ? "简体中文" : "English"}
        </Dropdown.Item>
      )
  );

  // 切换语言hook
  const { t } = useTranslation();

  //nav-status state
  const [isOpen, setIsOpen] = useState(false);
  const handleClickMore = () => {
    setIsOpen((prev) => !prev);
  };

  // 点击导航标签事件
  const handleClickNavTag = (anchorClassName) => {
    setIsOpen(false);
    // 滑动到相应部分
    let anchorElement = document.getElementsByClassName(anchorClassName)[0];
    if (anchorElement) {
      anchorElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  // 判断是否是手机
  const isPhone = useMediaQuery("(max-width:425px)");

  return (
    <header>
      <div className="logo">UtcPay</div>
      <div className="titles">
        <p onClick={() => handleClickNavTag("start-container")}>{t("home")}</p>
        <p onClick={() => handleClickNavTag("intro-container")}>
          {t("product")}
        </p>
        <p onClick={() => handleClickNavTag("application-container")}>
          {t("application-field")}
        </p>
        <p onClick={() => handleClickNavTag("application-container")}>
          {t("development-tools")}
        </p>
        <p onClick={() => handleClickNavTag("advantages-container")}>
          {t("advantage")}
        </p>
        <p onClick={() => handleClickNavTag("contact-container")}>
          {t("contact-us")}
        </p>
      </div>

      <div>
        <Dropdown>
          <Dropdown.Toggle variant="success" id="dropdown-basic">
            {currentLanguage === "zh" ? "简体中文" : "English"}
          </Dropdown.Toggle>

          <Dropdown.Menu>{DropDownItemList}</Dropdown.Menu>
        </Dropdown>
      </div>

      <img
        src={!isOpen ? "images/more.png" : "images/close.png"}
        // src="images/more.png"
        alt="more"
        className="more"
        onClick={handleClickMore}
      />

      <Modal
        show={isOpen && isPhone}
        fullscreen={true}
        onHide={() => setIsOpen(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>UtcPay</Modal.Title>
        </Modal.Header>
        <Modal.Body id="nav-container">
          <div onClick={() => handleClickNavTag("start-container")}>
            {" "}
            {t("home")}
          </div>
          <div onClick={() => handleClickNavTag("intro-mobile-container")}>
            {t("product")}
          </div>
          <div onClick={() => handleClickNavTag("application-container")}>
            {t("application-field")}
          </div>
          <div onClick={() => handleClickNavTag("function-container")}>
            {t("development-tools")}
          </div>
          <div onClick={() => handleClickNavTag("advantages-container")}>
            {t("advantage")}
          </div>
          <div onClick={() => handleClickNavTag("contact-container")}>
            {t("contact-us")}
          </div>
        </Modal.Body>
      </Modal>
    </header>
  );
};

export default Header;
